@import "mixins";

//pop-up gallerie

.wp-popup{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: #1d050eA1;
    backdrop-filter: blur(1rem);
    z-index: 5;
    transition: all .8s ease;
    .wp-popup-close{
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .wp-window{
        position: relative;
        width: 80%;
        height: 80%;
        background-color: var(--WhitePearl);
        padding: 2rem;
        overflow-y: scroll;
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        @include xs{
            width: 100%;
            height: 100%;
        }

        .wp-button-popup{
            align-self: flex-start;
            border: none;
            padding: 1.5rem 3rem;
            font-family: var(--Aileron);
            text-transform: capitalize;
            position: relative;
            overflow: hidden;
            transition: all .5s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(45deg, var(--BlueGreen), var(--AquaMarine));
            background-size: 400% 400%;
            animation: gradient 10s ease infinite;
            color: var(--Licorice);
            cursor: pointer;
            letter-spacing: .04rem;

            .wp-button-popup-close{
                position: absolute;
                transition: all .5s ease-in-out;
                transform: translateY(0);
            }

            &::before{
                box-sizing:border-box;
                position: absolute;
                content: 'x';
                transform: translateY(5rem);
                transition: all .5s ease-in-out;
                color: var(--WhitePearl);
                font-size: 1.02rem;
            }

            &:hover{
                .wp-button-popup-close{
                    transform: translateY(-5rem);
                }
                &::before{
                    transform: translateY(0);
                }
            }
        }

        .wp-popup-title{
            text-transform: capitalize;
        }

        .pop-up-links{
            display: flex;
            justify-content: center;
            .wp-popup-link{
                width: 25%;
                padding: .5rem 1rem;
                border: 1px solid var(--MyGray);
                color: var(--MyGray);
                text-decoration: none;
                transition: all .3s ease;

                @include sm {
                    width: 50%;
                }

                @include xs{
                    width: 100%;
                }

                &:hover{
                    background-color: var(--MyGray);
                    color: var(--WhitePearl);
                }
            }
        }


        .popup-card-container{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin: 10vmin;
            height: 70vh;
            overflow: hidden;
            overflow-y: scroll;


            @include sm{
                overflow-y: scroll;
                margin: 0;
                height: 50vh;
            }

            .popup-card{
                flex: 1;
                overflow: hidden;
                transition: .5s;
                box-shadow: 0 20px 30px rgba(0,0,0,.1);
                line-height: 0;
               
                img {
                    width: 100%;
                    height: calc(100% - 10vh);
                    object-fit:contain;
                    transition: .5s;

                    @include sm{
                        height: auto;
                    }
                }

                &:hover {
                    flex: 1 1 50%; 
                    img {
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                }    
            }
        }
    }
}



.video-container{
    .wp-window-video{
        position: relative;
        width: 45%;
        height: 45%;

        @include sm {
            width: 100%;
            height: 100%;
        }
    }

    .video-close-popup{
        position: absolute;
        border-radius: 100vw;
        top: -55vh;
        right: 1vw;
        background-color: var(--Aileron) !important;
        color: var(--WhitePearl) !important;
        border-color: var(--WhitePearl) !important;
        display: none;

        @include sm{
            display: block;
            top: -30vh;
            z-index: 10;
            right: -2dvw;
        }

        @include xs{
            display: block;
            top: -15vh;
        }
    }

    .popup-video-container{
        width: 80%;
        @include sm{
            transform: translate(0, 50%);
            width: 100%;
        }

        @include xs {
            transform: translateY(40vh);
        }
    }
}