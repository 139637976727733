@import "mixins";

.carousel-container {
    width: 100%;
    display: flex;
    display: block;

    .carousel-wrapper {
        display: flex;
        width: 100%;
        position: relative;
        left: 0;

        .carousel-content-wrapper {
            overflow: hidden;
            width: 100%;
            height: 33.3333vh;

            .carousel-content {
                display: flex;
                transition: all 250ms linear;
                -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
                scrollbar-width: none;  /* hide scrollbar in Firefox */
                height: 100%;

                .test2dcar{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--AquaMarine);
                    height: 100%;
                    color: var(--WhitePearl);
                }
            }
        }
    }
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    font-weight: 900;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 100%;
    border: none;
}

.left-arrow {
    left: 0;
    background: linear-gradient(90deg, #1d050e54 0%, transparent 70%);

}

.right-arrow {
    right: 0;
    background: linear-gradient(-90deg, #1d050e54 0%, transparent 70%);


}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}