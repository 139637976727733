/* Principales couleurs */

:root{
    --WhitePearl : #f8f6f0;
    --Mint : #cce8cc;
    --AquaMarine : #66cc99;
    --Zomp : #339999;
    --Licorice : #1d050e;
    --BlueGreen : #96c8c5;
    --MyGray : #544147;
    --AlphaWhite : #f8f6f051;
    --AlphaBlack : #1d050e51;
    --AlphaBG : #96c8c551;
    --Thin : Aileron-Thin;
    --Light: Aileron-Light;
    --Regular: Aileron;
    --Heavy : Aileron-Heavy;
    --anim-basic : fade-in-up .8s ease-in-out;
    --anim-basic-down : fade-in-down .8s ease-in-out;
}