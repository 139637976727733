@font-face {
  font-family: Aileron-Heavy;
  src: url(Styles/font/Aileron/Aileron-Heavy.otf);
}

@font-face {
  font-family: Aileron;
  src: url(Styles/font/Aileron/Aileron-Regular.otf);
}

@font-face {
  font-family: Aileron-Light;
  src: url(Styles/font/Aileron/Aileron-Light.otf);
}

@font-face {
  font-family: Aileron-Thin;
  src: url(Styles/font/Aileron/Aileron-Thin.otf);
}

@font-face {
  font-family: Arvo;
  src: url(Styles/font/Arvo/Arvo-Regular.ttf);
}

@font-face {
  font-family: Arvo-Bold;
  src: url(Styles/font/Arvo/Arvo-Bold.ttf);
}


body {
  margin: 0;
  font-family: Aileron-Heavy, Aileron, Aileron-Light,
    sans-serif;
}

code {
  font-family: Arvo, Arvo-Bold,
    monospace;
}
