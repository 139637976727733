//animation de dégradés
@keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  //form
  @keyframes inputHighlighter {
    from{ background: var(--AquaMarine);}
    to { width: 0; background: transparent;}
  }
  //drop
  @keyframes drop{
    0%{
      top: 100%;
    }
    100%{
      top:-50%;
    }
  }
  //classic
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-5vw);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .fadeIn {
    animation: fadeIn 1s;
  }
  .fade-in-out{
    animation: var(--anim-basic);
  }
  .fade-in-down{
    animation: var(--anim-basic-down);
  }
  @keyframes fade-in-up {
    0%{
      transform: translateY(5rem);
      opacity: 0;
    }
    100%{
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-down {
    0%{
      transform: translateY(-5rem);
      opacity: 0;
    }
    100%{
      transform: translateY(0);
      opacity: 1;
    }
  }

 //carrés
 @keyframes squares { 
  0% { transform: translateY(100%) rotate(-50deg); background-color: var(--BlueGreen);}
  50% { background-color: var(--WhitePearl) }
  100%   { transform: translateY(calc(-100vh + -100%)) rotate(20deg) }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float2 {
	0% {
		transform: translatey(10rem) rotate(-20deg);
	}
	50% {
		transform: translatey(-10rem);
	}
	100% {
		transform: translatey(10rem) rotate(-20deg);
	}
}

//tremblement

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

//opacité

@keyframes appear{
  0%{opacity: 0;};
  100%{opacity: 1};
}
