/* menu portfolio */
@import "mixins";

.menu-portfolio-container{
  position: relative;
  background-color: var(--WhitePearl);
  height: 100vh;
  overflow: hidden;

    .slider-navList{
      position: absolute;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      left: -2rem;
     

      @include sm{
        display: none;
      }
      
        li{
          position: relative;
          font-size: 5rem;
          font-family: var(--Heavy);
          -webkit-text-stroke: 1px var(--MyGray);
          color: transparent;
          cursor: pointer;
          transition: all .5s ease;
          &:hover{
            transform: translateX(2rem);
          }
          
          span{
          transition: all .5s ease;
            &:hover{
              color: var(--MyGray);   
            }
          }
        }
    }

    button{
      position: absolute;
      font-size: 5rem;
      background: none;
      border: none;
      display: none;
      &:last-child{
        top: 45vh;
        display: block;
        font-size: 2rem;
        animation: float 3s ease-in-out infinite;
      }
      @include sm {
        display: block;
        font-size: 3.5rem;
      }
    }

    .voir-button{
      text-decoration: none;
      position: relative;
      font-size: 1.5rem;
      background: none;
      top: 5rem;
      border: .5px solid var(--Licorice);
      color: var(--Licorice);
      padding: .5rem 3rem;
      font-family: var(--Regular);
      overflow: hidden;
      z-index: 1;
      transition: all .3s ease-in-out;
      background: var(--AlphaBlack);
      backdrop-filter: blur(.5rem);

      &::before{
        content: '\2192';
        font-size: 1rem;
        position: absolute;
        top: -8%;
        left: -100%;
        width: 100%;
        height: 110%;
        z-index: 0;
        background: linear-gradient(45deg, var(--Licorice), var(--MyGray));
        background-size: 400% 400%;
        animation: gradient 10s ease infinite;
        transition: all .3s ease-in-out;
        color: var(--WhitePearl);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover{
        color: var(--WhitePearl);

        &::before{
          top: -8%;
          left: 0;
          font-size: 2.5rem;
        }
      }


    }

    .slider__btn-left, .slider__btn-right{
      // border: solid 1px var(--Licorice);
      bottom: 3rem;
      cursor: pointer;
      padding: .5rem;
      transition: all .3s ease;
      font-size: 1.5rem;
    }

    .slider__btn-left{
      left: 5rem;
      &:hover{
        transform: translateX(-5px);
      }
      @include xs{
        left: 2rem;
      }
    }

    .slider__btn-right{
      right: 5rem;
      &:hover{
        transform: translateX(5px);
      }
      @include xs{
        right: 2rem;
      }
    }

    .slider{
      height: 100%;
      width: 100%;  

      .slider-bg{
        width: 100%;
        height: 100%;
        position: absolute;

        @media screen and (max-width : 1580px) {
            filter: blur(.15rem);
        }

        @include sm{
            filter: blur(.3rem);
        }
        @include md{
            filter: blur(.3rem);
        }
      }
    
        .slider--content{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;


            .slider--feature{
              position: relative;
              display: flex;
              align-items: center;
              flex-direction: column;
              .slider-main-title{
                text-align: left;
                position: absolute;
                top: -7rem;
                left: 0;
                font-size: 2rem;
                border-bottom: 1px solid var(--Licorice);
                width: 25%;
                padding-bottom: .5rem;

                @include sm {
                  font-size: 1.5rem;
                  width: 80%;
                }
              
              }

              .h1{
                border: .5px solid var(--Licorice);
                padding: 2rem 15rem;
                @include md{
                  padding: 2rem 5rem;
                }

                @include sm{
                  padding: 2rem 2rem ;
                }

                @include xs {
                  padding: 1rem 1rem;
                }
              }

              p{
                position: relative;
                font-family: var(--Light);
                text-align: center;
                width: 100%;
                top: 1rem;
                // @include sm {
                //   top: 18vh;
                // }
                // @include xs {
                //   top: 15vh;
                // }
              }

              ul{
                position: absolute;
                display: flex;
                list-style-type: none;
                width: 100%;
                justify-content: flex-start;
                top: -3rem;
                  li{
                    img{
                      height: 2.5rem;
                    }
                  }
              }

              span{
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: -15rem;
              }
            }
        }
    }

}



