.cloud {
    position: absolute;
    overflow: hidden;
    width: 1px; height: 170px;
    transform: translate(-150%, -150%);
    border-radius: 50%;
    filter: url(#filter);
}

.img-clouds{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
}

.cloud1{
    background-image: url("../assets/img/bg/clouds/cloud1.png");
}

.cloud2{
    background-image: url("../assets/img/bg/clouds/cloud3.png");
    opacity: .5;
}