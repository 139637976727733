@import "mixins";
/* MENU WEB PROJECTS */
.MenuWP{
    background-color: var(--WhitePearl);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;

    //partie slider
    .slider-container{
        width: 100%;
    }

    .slider {
        position: relative;
        width: 100%;
        height: 100vh;
        &:focus{
            outline: none;
        }

        .aud-spline{
            position: absolute;
            top :0;
            right: 0;
            transform: translate(25rem);
        }


        .slider-bg{
            width: 100%;
            height: 100%;
            position: absolute;

            @media screen and (max-width : 1580px) {
                filter: blur(.15rem);
            }

            @include sm{
                filter: blur(.3rem);

            }
            @include md{
                filter: blur(.3rem);
            }
        }

        .slider-navList{
            position: absolute;
            left: -2rem;
            list-style-type: none;
            display: flex;
            height: 100%;
            align-items: left;
            justify-content: space-between;
            flex-direction: column;
            li{
                font-size: 5rem;
                font-family: var(--Heavy);
                -webkit-text-stroke: 1px var(--MyGray);
                color: transparent;
                cursor: pointer;
                transition: all .5s ease;

                &:hover{
                    color: var(--MyGray);
                    transform: translateX(3.5rem);
                    -webkit-text-stroke: none;
                }
            }

            .navList-active{
                color: var(--Licorice);
            }

            @include sm{
                display: none;
            }
        }
        
        .slider--content{
            display: flex;
            position: relative;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .slider--feature{
                text-align: left;
                position: relative;
                display: flex;
                flex-direction: column;
                z-index: 1;

                .slider-logo{
                    position: absolute;
                    height: 3rem;
                    top: -6rem;
                    left: 8rem;
                }

                .slider-tech{
                    display: flex;
                    margin-top: 1rem;
                    list-style-type: none;
                    flex-wrap: wrap;
                    gap: 2rem;
                    li{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        img{
                            width: 2rem;
                        }
                        .figma{
                            width: auto;
                            height: 2rem;
                        }
                        span{
                            font-family: var(--Regular);
                        }
                    }
                }

                .feature--title{
                    position: relative;
                    top: 0;
                    text-transform: capitalize;
                    
                    h1{
                        position: absolute;
                        top: -5rem;
                        font-size: 1.25rem;
                        font-family: var(--Regular);
                    }

                    &::after{
                        content : "";
                        position: absolute;
                        left: 0;
                        top: -2rem;
                        background-color: var(--Licorice);
                        width: 10rem;
                        height: 1px;
                    }
                }

                p{
                    position: relative;
                    font-family: var(--Light);
                    margin-top: .5rem;
                    max-width: 45rem;
                    line-height: 1.5rem;
                    background-color: rgba(248, 246, 240, 0.4);
                    backdrop-filter: blur(.5rem);
                    transition: all .1s ease-in;


                    @include sm {
                        background: transparent;
                    }
                }

                .date-wp{
                    position: absolute;
                    top: -10rem;
                    letter-spacing: 1rem;
                    background-color: transparent;
                }

                .feature__btn{
                    position: relative;
                    margin-top: 2rem;
                    width: 20rem;
                    padding: 1rem 3rem;
                    border: none;
                    background: linear-gradient(45deg, var(--BlueGreen), var(--AquaMarine));
                    background-size: 400% 400%;
                    animation: gradient 10s ease infinite;
                    overflow: hidden;
                    cursor: pointer;

                    @include xs{
                        padding: 1rem 0;
                        width: 100%;
                    }

                    span{
                        &:first-child{
                            visibility: hidden;
                            position: initial;
                        }

                        &:last-child{
                            position: absolute;
                            color: var(--WhitePearl);
                            font-size: 3rem;
                            top: -.6rem;
                            left: -5rem;
                            transition: all .5s ease;
                        }
                    }

                    &::before{
                        z-index: 1;
                        top: .1rem;
                        left: .1rem;
                        position: absolute;
                        content: "Voir le projet";
                        font-family: var(--Light);
                        background-color: var(--WhitePearl);
                        padding: .9rem 2.9rem;
                        transition: all .5s ease;
                        width: 19.75rem;
                        @include xs{
                            padding: .9rem 0;
                            width: 99%;
                        }
                    }

                    &:hover, &:focus{
                        &::before{
                            opacity: 0;
                            padding: 0;
                            top: 0;
                            content: "";
                        }

                        span:last-child{
                            left: 9rem;
                        }
                        
                    }
                }
            }
        }

        .slider__btn-left, .slider__btn-right{
            bottom: 3rem;
            display: none;
            position: absolute;
            width: 5rem;
            height: 5rem;
            border-radius: 100rem;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            transition: all .3s ease;
            background: none;

            @include sm{
                display:  block;
            }

            span{
                transform: translateY(-1rem);
                transition: all .3s ease;
                color: var(--Licorice);
            }

            &:hover{
                span{
                    transform: translateX(-3px);
                }
            }
        }

        .slider__btn-left{
            left: 0; 
        }

        .slider__btn-right{
            right: 0;
        }
    }
}

//slider

