@import "mixins";

//Partie 2D

.p-logo{
    .deuxd-container{
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        @include sm{
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));

        }
    }
}
.p-illu{
    .deuxd-container{
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(50%, 1fr));

        @include xs{
            grid-template-columns: repeat(auto-fill, minmax(60%, 1fr));
        }
    }
}
.deuxd-container{
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: absolute;
        
        display : grid;
    
        img{
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
        }
}

//partie 3d 

.ui-desc{
    margin-top: 1rem;
    font-family: var(--Thin);
}

.UI{
    position: absolute;
    z-index: 5;
}

//partie print
.print-container{
    position: absolute;
    width: 100%;
    height: 100%;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


//Partie vidéo
.video-container{
    position: absolute;
    width: 100%;
    height: 100%;
    .video-gif{
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include md{
            height: 100vh;
            width: auto;
            transform: translate(-50vw);
        }

        @include sm {
            height: 100vh;
            width: auto;
            transform: translate(-50vw);
        }
    }

    .video-button{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;

        button{
            position: absolute;
            transform: translate(-50%, 52vh);
            background-color: var(--AlphaWhite);
            padding: 1rem 1.5rem;
            font-family: var(--Aileron);
            border: 1px solid var(--Licorice);
            color: var(--Licorice);
            letter-spacing: .05rem;
            cursor: pointer;
            transition:  all .5s ease;
            font-size: 1rem;
            backdrop-filter: blur(.5rem);

            &:hover{
                background-color: var(--Licorice);
                color: var(--WhitePearl);
            }
        }
    }
}