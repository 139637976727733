/* Page d'accueil */
@import "mixins";

.home-header{
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: mix( #339999, #f8f6f0, 50%);
    height: 100vh;
    animation: appear 1s linear;
    overflow: hidden;

    .home-main-title{
        color: mix( #339999, #f8f6f0, 50%);
        position: absolute;
        width: 100%;
        font-size: 10rem;
        transform: translateY(100%);
        text-shadow: 
        -0.0075em 0.0075em .5rem mix(#f8f6f0, #339999, 94%),
        0.005em 0.005em .5rem mix(#f8f6f0, #339999, 60%),
        0.01em 0.01em .5rem mix(#f8f6f0, #339999, 62%), 
        0.015em 0.015em mix(#f8f6f0, #339999, 64%), 
        0.02em 0.02em .5rem mix(#f8f6f0, #339999, 66%), 
        0.025em 0.025em .5rem mix(#f8f6f0, #339999, 68%),
        0.03em 0.03em .5rem mix(#f8f6f0, #339999, 70%),
        0.035em 0.035em .5rem mix(#f8f6f0, #339999, 72%);

        @include md{
            font-size: 6rem;
            line-height: 15rem;
            z-index: 1;
        }

        @include sm{
            font-size: 5rem;
            line-height: 20rem;
            transform: translateY(0);
            z-index: 1;
        }
    }

    .swiper{
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;

        .cloud1{
            position: absolute;
            bottom: 0;
        }

        .cloud2{
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(-90deg) translateY(60%);
            z-index: 1;
        }

        .swiper-next{
            display: block;
            position: absolute;
            z-index: 5;
            color: var(--Licorice);
            bottom: 5rem;
            cursor: pointer;
            right: 5rem;
            font-size: 1.25rem;
            transition: all .5s ease;
            span{
                margin-left: .5rem;
                transition: all .5s ease;
            }

            @include xs {
                right: 1rem;
                bottom: 2rem;
            }

            &:hover{
                span{
                    margin-left: 1rem;
                }     
            }
        }

        .swiper-prev{
            display: block;
            position: absolute;
            z-index: 5;
            color: var(--Licorice);
            bottom: 5rem;
            cursor: pointer;
            font-size: 1.25rem;
            transition: all .5s ease;
            left: 5rem;

            span{
                margin-right: .5rem;
                transition: all .5s ease;
            }

            @include xs {
                left: 1rem;
                bottom: 2rem;
            }

            &:hover{
                span{
                    margin-right: 1rem;
                }     
            }
        }

        .swiper-button-disabled{
            opacity: 0;
            cursor: none;
        }

        .container{
            height: 100%;
            position: relative;
        }

        .apropos{
            width: 100%;
            height: 100%;

            .apropos-text, .apropos-img{
                width: 50%;
            }

            .apropos-text{
                text-align: left;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                background-color: var(--AlphaBG);
                backdrop-filter: blur(1rem);
                @include sm{
                    top: 5rem;
                    transform: translateY(0);
                    width: 80%;
                }

                @include xs{
                    width: 100%;
                }
                h2{
                    font-size: 4rem;
                    letter-spacing: .5rem;
                    
                }
                p{
                    font-family: var(--Light);
                    
                    a{
                        position: relative;
                        text-decoration: none;
                        color: var(--Licorice);
                        transition: all .2s ease-in-out;
                        display: inline-block;

                        
                        &::before{
                            transition: all .2s ease-in-out;
                            content: "";
                            position: absolute;
                            
                            top: 0;
                            width: 110%;
                            height: .5rem;
                            background-color: var(--Zomp);
                            transform: translateY(1rem);
                            z-index: -1;

                            @include sm{
                                width: 100%;
                            }
                        }

                        

                        &:hover{
                            &::before{
                                transform: translateY(.5rem);
                                background-color: var(--Mint);
                                width: 100%;
                            }
                        }
                    }
                    
                }
            }

            .apropos-img{

                img{
                    position: absolute;
                    right: -10rem;
                    bottom: 0;
                    width: 30rem;
                    @include sm{
                        bottom: -15rem;
                    }
                    @include xs{
                        right: -5rem;
                    }
                }
            }
        }
    }
}