//Menu 
@import "mixins";

.menu-btn{
    position: fixed;
    top : 6rem;
    right : 6rem;
    border-radius : 50%;
    height : 5rem;
    width : 5rem;
    cursor : pointer;
    z-index : 1000;
    color: var(--Licorice);
    border: solid var(--Licorice) 1px;
    background-color: var(--AlphaWhite);
    backdrop-filter: blur(.5rem);
    text-align: center;
    @include md{
        top : 3rem;
        right : 3rem;
    }
    @include sm{
        top : 4rem;
        right : 4rem;
        height : 4rem;
        width : 4rem;
    }

    .menu-icon::before, .menu-icon::after{
        background-color: var(--Licorice);
    }

    &:hover{
        .menu-icon::before, .menu-icon::after{
            width: 2.5rem;
            @include sm{
                width: 2rem;
            }
        }

        .menu-icon{
            margin-left: 0;
        }
    }
}

.nav-bg{
    position: fixed;
    top: 5.5rem;
    right: 5.5rem;
    height: 6rem;
    z-index: 500;
    width: 6rem;
    border-radius: 50%;
    background-color: var(--Mint);
    background: radial-gradient( var(--BlueGreen), var(--WhitePearl));
    @media screen and (max-width : 767px){
        &{
            top : 3.5rem;
            right : 3.5rem;
        }
    }

    @include sm{
        top : 2.5rem;
        right : 2.5rem;
        height : 5rem;
        width : 5rem;
        
    }
}


.menu-icon{
    background-color: var(--WhitePearl);
    width: 2.5rem;
    height: 2px;
    display: inline-block;
    position: relative;
    margin-top: 2.5rem;
    transition: all 0.3s;
    margin-left: .5rem;

    @include sm{
        margin-top: 2rem;
        width: 2rem;
        
    }

    &::after,
    &::before{
        content: "";
        background-color: var(--WhitePearl);
        width: 2.5rem;
        height: 2px;
        display: inline-block;
        position: absolute;
        left: 0;
        transition: all .3s;
        width: 1rem;
    }
}

.menu-nav{
    position:fixed;
    right: 0;
    z-index: 600;
    transition: all 1s ease-in-out;
    width: 100%;

    .menu-list{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        position: absolute;
        list-style: none;
        top : 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        padding: 0;
        width: 70%;
        div{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 50%;
            height: 50vh;
            @media screen and (max-width : 767px){
                &{
                    height: 30vh;
                }
            }

            &:first-child{
                li:first-child::after{
                    content: "01";
                }

                li:last-child::after{
                    content: "02";
                    left: 12%;
                }
            }

            &:last-child{
                li:first-child::after{
                    content: "03";
                }

                li:last-child::after{
                    content: "04";
                }
            }

            li{
                position: relative;
                text-align: left;

                &::after{
                    position: absolute;
                    font-family: var(--Heavy);
                    font-size: 1rem;
                    left: 10%;
                    top: -0.5rem;
                    color: var(--MyGray);
                }

                &::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 1rem;
                    width: 20%;
                    height: 1px;
                    background-color: var(--MyGray);
                }

                &:first-child{
                align-self: flex-start;
                }
                &:last-child{
                    align-self: flex-end;
                    &::after{
                        left: 12%;
                    }
                }
        
        
                @media screen and (max-width : 767px){
                    &:first-child,&:last-child{
                        align-self: center;
                    }

                    &:last-child::after{
                        left: 10%;
                    }
                }
        
            }

        }

        @media screen and (max-width : 767px){
            &{
                flex-direction: column;
                height: 60vh;
            }
        }
     
        
    }


    .menu-item{
        position: relative;
        display: inline-block;
        font-size: 2rem;
        font-family: Aileron-light;
        text-transform: capitalize;
        text-decoration: none;
        color: mix( #1d050e, #f8f6f0, 75%);
        padding: 1rem 2rem;
        letter-spacing: 0.2rem;
        text-align: left;
        /*background-image: linear-gradient(120deg,transparent 0%, transparent 50%, var(--WhitePearl) 50%);
        background-size: 240%;
        transition: all 0.4s;*/

        @media screen and (max-width : 767px){
            &{
                font-size: 1.2rem;
            }
        }

        transition: all .4s ease;
        background: linear-gradient(to bottom, transparent 62%, #cce8cc80 0) center center/0% 75% no-repeat;

        &:hover, &:active{
            /*background-position: 100%;
            color: var(--Licorice);
            transform: translateX(1rem);
            animation: text-shadow 1.5s ease-in-out infinite;*/
            transform: translate3d(0, -8px, 0);
            background-size: 100% 100%;
            letter-spacing: 0.3rem;
        }


    }

}
