@import "mixins";

.portfoliod{
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: var(--WhitePearl);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sectionh1, .sectionh2{
        z-index: 2;
    }

    .return {
        backdrop-filter: blur(.5rem);
        text-decoration: none;
        position: absolute;
        z-index: 5;
        left: 8rem;
        top: 4rem;
        color: var(--Licorice);
        border: 1px solid var(--Licorice);
        padding: 1rem;
        transition: all .3s ease;
        background-color: var(--AlphaWhite);
        font-size: 1.15rem;
        @include sm {
            content: "";
            left: 1rem;
            font-size: 1rem;
        }

        span {
            font-size: 1.2rem;
            margin: 0 .5rem;
        }

        &:hover{
            background-color: var(--Licorice);
            color: var(--WhitePearl);
        }
    }


    h1{
        padding: 0;
        text-align: start;
        position: absolute;
        z-index: 5;
        left: 0;
        bottom: 5rem;
        -webkit-text-stroke: .1rem var(--MyGray);
        color: transparent;
        background-color: var(--AlphaWhite);
        backdrop-filter: blur(.5rem);
        padding: 1rem 1.5rem;

        @include md{
            bottom: 1rem;
        }

        @include sm{
            bottom: 1rem;
            left: initial;
        }
    }

    .slide-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .section-filter{
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, var(--WhitePearl), #f8f6f0a1);
            background-size: 400% 400%;
            animation: gradient 10s ease infinite;
            opacity: .4;
        }
        h2{
            letter-spacing: .3rem;
            color: var(--WhitePearl);
            background-color: var(--MyGray);
            padding: 1rem;
        }
    }

    .swiper{
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        top: initial;
        transform: none;
        right: initial;
        height: 10rem;
    }

    .swiper-pagination-bullet-active{
        background: var(--Licorice) !important;
        width: 1rem !important;
        height: 1rem !important;
        transition: all .5s ease-in-out;
        border-radius: 10rem;
    }

    .swiper-pagination-bullet{
        background: none;
        border: 1px solid var(--Licorice);
        width: .5rem;
        height: .5rem;
        transition: all .5s ease-in-out;
    }
    
}